import React, { useState, useEffect, memo } from 'react';
import './ProjectDetails.css';
import { useParams } from 'react-router-dom';
import { ProjectsList } from "../../../Projects List";
import { RecentProjectsList } from "../../../Recent Projects List";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaRegCircleCheck } from "react-icons/fa6";
import { HiOutlineMinusSmall } from "react-icons/hi2";
import AlertModal from '../../Alert Modal/AlertModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import {ProductViewer} from "../Project Viewer/Product Viewer";

function ProjectDetails({ selectedLanguage }) {
    const [projectDetails, setProjectDetails] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [isViewerOpen, setIsViewerOpen] = useState(false); // State for ProductViewer
    const { id } = useParams();

    useEffect(() => {
        const combinedProjectsList = [...ProjectsList, ...RecentProjectsList];
        const details = combinedProjectsList.find(project => project.id === parseInt(id));
        setProjectDetails(details || {});
    }, [id]);

    const handleLivePreviewClick = (e) => {
        if (!projectDetails?.livePreview) {
            e.preventDefault();
            setShowAlert(true);
        }
    };

    const closeAlert = () => setShowAlert(false);

    const openViewer = () => setIsViewerOpen(true); // Open the viewer
    const closeViewer = () => setIsViewerOpen(false); // Close the viewer

    const translate = (key) => {
        if (!key) return "";
        if (selectedLanguage === 'en') return key.en || "N/A";
        if (selectedLanguage === 'tr') return key.tr || "N/A";
        return key.fa || "N/A";
    };

    return (
        <main className="main" lang={selectedLanguage === 'fa' ? 'fa' : 'en'}>
            <section className="project-details-section">
                {projectDetails ? (
                    <div className="project-details-container grid container">
                        <div className="pd-slider-container">
                            <div className="project-details-info">
                                <h2 className="project-details-title">
                                    {translate(projectDetails.title)}
                                </h2>
                                <a
                                    href={projectDetails.livePreview || "#"}
                                    className="pd-live-link button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Live Preview"
                                    onClick={handleLivePreviewClick}
                                >
                                    <BsFillGrid1X2Fill className="pd-live-link-icon" /> Live Preview
                                </a>
                                <p className="details-info-description">
                                    {translate(projectDetails.description)}
                                </p>
                            </div>

                            <div className="pd-swiper-container">
                                <Swiper
                                    modules={[Navigation]}
                                    navigation={true}
                                    style={{
                                        "--swiper-navigation-color": 'var(--black-color-light)',
                                        "--swiper-pagination-color": 'var(--black-color-light)',
                                    }}
                                    loop
                                    className="project-details-swiper"
                                >
                                    {projectDetails.img?.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img
                                                src={image}
                                                alt={`Slide ${index + 1}`}
                                                className="pd-slider-image"
                                                draggable="false"
                                                onContextMenu={(e) => e.preventDefault()}
                                                onClick={openViewer} // Open viewer on click
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>

                        <div
                            className="pd-feature-box"
                            dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}
                        >
                            {showAlert && (
                                <AlertModal
                                    message="Live preview is not available for this project."
                                    onClose={closeAlert}
                                />
                            )}

                            <h2 className="pd-feature-title">
                                {selectedLanguage === 'en' ? 'Features' :
                                    selectedLanguage === 'tr' ? 'Özellikler' : 'ویژگی ها'}
                                <HiOutlineMinusSmall className="feature-title-icon" />
                            </h2>
                            <ul className="pd-feature-list">
                                {projectDetails.feature?.[selectedLanguage]?.length ? (
                                    projectDetails.feature[selectedLanguage].map((feature, index) => (
                                        <li className="pd-feature-item" key={index}>
                                            <FaRegCircleCheck className="pd-feature-icon" /> <h4>{feature}</h4>
                                        </li>
                                    ))
                                ) : (
                                    <li>No features available</li>
                                )}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <h1 className="project-details-title">Not found</h1>
                )}
            </section>

            {isViewerOpen && (
                <ProductViewer
                    closeViewer={closeViewer}
                    selectedProduct={{
                        imageUrl: projectDetails.img || [], // Fallback to an empty array
                    }}
                />
            )}



        </main>
    );
}

export default memo(ProjectDetails);

import React, { useRef, useState } from 'react';
import './Contact.css';
import curved_arrow from '../../../assest/curved arrow.svg';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";
import { TiTick } from "react-icons/ti";

import emailjs from '@emailjs/browser';


function Contact({ selectedLanguage }) {
    const [showMessage, setShowMessage] = useState(false);

    const handleShowMessage = () => {
        setShowMessage(true);

        // Hide the message after 3 seconds
        setTimeout(() => {
            setShowMessage(false);
        }, 3000);
    };

    const form = useRef();

    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        user_subject: '',
        user_message: '',
    });

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_mdi3b4a', 'template_92mrt3h', form.current, 'qoQqXCBRWR0-CiGfM')
            .then((result) => {
                console.log(result.text);
                setFormData({
                    user_name: '',
                    user_email: '',
                    user_subject: '',
                    user_message: '',
                });
                handleShowMessage();
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const darClass = selectedLanguage === 'fa' ? 'dari' : '';
    
    return (
        <main className='main' lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <section className="contact-section">
                <div className="contact-container grid">
                    <div className="contact-data">
                        <h2 className={`section_title-2 ${darClass}`} >
                            <span>
                                {selectedLanguage === 'en' ? 'Contact Us'
                                    : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                                        : selectedLanguage === 'fa' ? 'با ما تماس بگیرید'
                                            : null
                                }
                            </span>
                        </h2>
                        <p className="contact-description">
                            {selectedLanguage === 'en' ? 'Drop us a message. We will ensure a thorough review of all emails.'
                                : selectedLanguage === 'tr' ? 'Bize bir mesaj bırakın. Tüm e-postaları titizlikle inceleyeceğiz.'
                                    : selectedLanguage === 'fa' ? 'برای ما پیامی بگذارید. ما اطمینان خواهیم داد که تمامی ایمیل‌ها بررسی شوند.'
                                        : null
                            }
                        </p>

                        <p className="contact-description">
                            {selectedLanguage === 'en' ? 'We require your Full Name and a Contact Email Address. Rest assured, you\'ll only hear back from us based on your response.'
                                : selectedLanguage === 'tr' ? 'Adınız ve e-posta adresinizi belirtmeniz gerekmektedir. Yanıtınıza bağlı olarak sizlere geri dönüş yapacağız.'
                                    : selectedLanguage === 'fa' ? 'نام کامل و آدرس ایمیل خود را وارد کنید. مطمئن باشید فقط در صورت نیاز با شما تماس خواهیم گرفت.'
                                        : null
                            }
                        </p>

                        <div className="geometric_box"></div>
                    </div>

                    <div className="contact-form-box">
                        <h2 className="contact-subtitle">
                            {selectedLanguage === 'en' ? 'Send Us A Message'
                                : selectedLanguage === 'tr' ? 'Bize Bir Mesaj Gönderin'
                                    : selectedLanguage === 'fa' ? 'برای ما پیامی بفرستید'
                                        : null
                            }
                        </h2>

                        <form className="contact-form" ref={form} onSubmit={sendEmail}>
                            <div className="contact-form-group">
                                <div className="contact-input-box">
                                    <input
                                        type="text" name="user_name"
                                        className="contact-input"
                                        value={formData.user_name}
                                        onChange={handleInputChange}
                                        placeholder={
                                            selectedLanguage === 'en' ? 'First Name'
                                                : selectedLanguage === 'tr' ? 'Ad'
                                                    : selectedLanguage === 'fa' ? 'نام'
                                                        : null}
                                        required
                                        lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                    />
                                    <label htmlFor="user_name" className={`contact-label ${darClass}`}>
                                        {selectedLanguage === 'en' ? 'First Name'
                                            : selectedLanguage === 'tr' ? 'Ad'
                                                : selectedLanguage === 'fa' ? 'نام'
                                                    : null
                                        }
                                    </label>
                                </div>

                                <div className="contact-input-box">
                                    <input
                                        type="email"
                                        name="user_email"
                                        className="contact-input"
                                        value={formData.user_email}
                                        onChange={handleInputChange}
                                        placeholder={
                                            selectedLanguage === 'en' ? 'Email Address'
                                                : selectedLanguage === 'tr' ? 'E-posta Adresi'
                                                    : selectedLanguage === 'fa' ? 'آدرس ایمیل'
                                                        : null}
                                        lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                        required
                                    />
                                    <label htmlFor="user_email" className={`contact-label ${darClass}`}>
                                        {selectedLanguage === 'en' ? 'Email Address'
                                            : selectedLanguage === 'tr' ? 'E-posta Adresi'
                                                : selectedLanguage === 'fa' ? 'آدرس ایمیل'
                                                    : null
                                        }
                                    </label>
                                </div>
                            </div>

                            <div className="contact-input-box">
                                <input type="text" name="user_subject" className="contact-input" placeholder={
                                    selectedLanguage === 'en' ? 'Subject'
                                        : selectedLanguage === 'tr' ? 'Konu'
                                            : selectedLanguage === 'fa' ? 'موضوع'
                                                : null}
                                       lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                       value={formData.user_subject} onChange={handleInputChange} required
                                />
                                <label htmlFor="user_subject" className={`contact-label ${darClass}`}>
                                    {selectedLanguage === 'en' ? 'Subject'
                                        : selectedLanguage === 'tr' ? 'Konu'
                                            : selectedLanguage === 'fa' ? 'موضوع'
                                                : null}
                                </label>
                            </div>

                            <div className="contact-input-box contact-area">
                                <textarea
                                    className="contact-input"
                                    name='user_message'
                                    id="message"
                                    required
                                    placeholder={
                                        selectedLanguage === 'en' ? 'Message'
                                            : selectedLanguage === 'tr' ? 'Mesaj'
                                                : selectedLanguage === 'fa' ? 'پیام'
                                                    : ''
                                    }
                                    value={formData.user_message}
                                    lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                                    onChange={handleInputChange}
                                >
                                </textarea>
                                <label htmlFor="user_message" className={`contact-label ${darClass}`}>
                                    {selectedLanguage === 'en' ? 'Message'
                                        : selectedLanguage === 'tr' ? 'Mesaj'
                                            : selectedLanguage === 'fa' ? 'پیام'
                                                : null
                                    }
                                </label>
                            </div>

                            <button
                                className="contact-button button"
                                type="submit"
                                lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                            >
                                <FaPaperPlane/>
                                {selectedLanguage === 'en' ? 'Send'
                                    : selectedLanguage === 'tr' ? 'Gönder'
                                        : selectedLanguage === 'fa' ? 'ارسال'
                                            : null
                                }
                            </button>

                            {showMessage &&
                                <div className="contact-popup">
                                    <p className="contact-popup-text">
                                        {selectedLanguage === 'en' ? 'Message sent!'
                                            : selectedLanguage === 'tr' ? 'Mesaj gönderildi!'
                                                : selectedLanguage === 'fa' ? 'پیام ارسال شد!'
                                                    : null
                                        }
                                    </p>
                                    <TiTick className='contact-popup-icon'/>
                                </div>
                            }
                        </form>
                    </div>

                    <div className="contact-social-box">
                        <img src={curved_arrow} alt="image" className="contact-social-arrow"/>
                        <div className="contact-social-data">
                            <div>
                                <p className="contact-social-title">
                                    {selectedLanguage === 'en' ? 'not send emails'
                                        : selectedLanguage === 'tr' ? 'e-posta göndermeyin'
                                            : selectedLanguage === 'fa' ? 'ایمیل نفرستید'
                                                : null
                                    }
                                </p>
                                <p className="contact-social-subtitle">
                                    {selectedLanguage === 'en' ? "Let's get social."
                                        : selectedLanguage === 'tr' ? 'Sosyal medyada bizi takip edin.'
                                            : selectedLanguage === 'fa' ? 'با ما در شبکه‌های اجتماعی همراه شوید.'
                                                : null
                                    }
                                </p>
                            </div>
                            <br/>
                            <div className="contact-social-link-list">
                                <a href="https://www.facebook.com/share/sq3BHocRxXnUFZoV/?mibextid=LQQJ4d" target="_blank" className="contact-social-link" rel="noreferrer">
                                    <FaFacebook />
                                </a>
                                <a href="https://www.instagram.com/nacode.webdev/?igsh=NTZyemRkcjdsem05&utm_source=qr" target="_blank" className="contact-social-link" rel="noreferrer">
                                    <FaInstagram />
                                </a>
                                <a href="https://www.linkedin.com/in/nasirullah-ahmadi-64785b294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank" className="contact-social-link" rel="noreferrer">
                                    <FaLinkedin />
                                </a>
                            </div>
                        </div>
                        
                    </div>

                </div>

            </section>
        </main>
    );
}

export default Contact;

import React from "react";
import './About.css';
import { FaCode } from "react-icons/fa";


function About ({selectedLanguage}) {

    return (
        <main className='main' lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <section className="about_section " id="about">
                <h2 className="about-title section_title-1">
                    {selectedLanguage === 'en' ? 'About Us'
                        : selectedLanguage === 'tr' ? 'Hakkımızda'
                            : selectedLanguage === 'fa' ? 'درباره ما'
                                : null
                    }
                </h2>

                <div className="about-container container grid">
                    <p className='about-description-one'>
                        {selectedLanguage === 'en' ? 'We transform brands through elevated digital experiences'
                            : selectedLanguage === 'tr' ? 'Markaları dijital deneyimlerle dönüştürüyoruz'
                                : selectedLanguage === 'fa' ? 'ما برندها را از طریق تجربیات دیجیتال ارتقا یافته متحول می‌کنیم.'
                                    : null
                        }
                    </p>

                    {
                        /*
                        <div className="shape-one ">
                        <div className="shape-one-quarter"></div>
                        <div className="shape-one-quarter"></div>
                        <div className="shape-one-quarter"></div>
                        <div className="shape-one-quarter"></div>
                        </div>
                        */
                    }

                    <div className='shape-two-container'>
                        <FaCode className='shape-two' />
                    </div>
                    
                    <div className="about-description-two">
                        {selectedLanguage === 'en' ? 'We\'re a global group of strategists, designers, and engineers building notable brands and premier digital experiences that captivate users worldwide. Our unified, AI-infused creative approach ensures scalability and consistency across all brand touch points by building robust design systems.'
                            : selectedLanguage === 'tr' ? 'Dünya çapında kullanıcıları büyüleyen dikkat çekici markalar ve birinci sınıf dijital deneyimler inşa eden küresel bir stratejistler, tasarımcılar ve mühendisler grubuyuz. Birleşik, yapay zeka destekli yaratıcı yaklaşımımız, sağlam tasarım sistemleri oluşturarak tüm marka temas noktalarında ölçeklenebilirlik ve tutarlılık sağlar.'
                                : selectedLanguage === 'fa' ? 'ما یک گروه جهانی از استراتژیست‌ها، طراحان و مهندسان هستیم که برندهای قابل توجه و تجربیات دیجیتال برتر را ایجاد می‌کنیم که کاربران را در سراسر جهان جذب می‌کند. رویکرد خلاقانه یکپارچه و مبتنی بر هوش مصنوعی ما اطمینان می‌دهد که مقیاس‌پذیری و ثبات در تمامی نقاط تماس برند حفظ شود و سیستم‌های طراحی قوی ایجاد کند.'
                                    : null
                        }
                    </div>

                    <div className='about-us-team'>
                        <h1 className='about-team-title section_title-1'>
                            {selectedLanguage === 'en' ? 'Our Team'
                                : selectedLanguage === 'tr' ? 'Ekibimiz'
                                    : selectedLanguage === 'fa' ? 'تیم ما'
                                        : null
                            }
                        </h1>

                        <p className='about-team-description'>
                            {selectedLanguage === 'en' ? 'Meet the dedicated professionals behind Nacode:'
                                : selectedLanguage === 'tr' ? 'Nacode\'un arkasındaki özverili profesyonellerle tanışın:'
                                    : selectedLanguage === 'fa' ? 'با حرفه‌ای‌های متعهد نیکود آشنا شوید:'
                                        : null
                            }
                        </p>

                        <ul className='about-team-list'>
                            <li>
                                {selectedLanguage === 'en' ? 'Daniel Walker, Founder & CEO'
                                    : selectedLanguage === 'tr' ? 'Daniel Walker, Kurucusu ve CEO\'su'
                                        : selectedLanguage === 'fa' ? 'دنیل واکر، بنیان‌گذار و مدیر عامل'
                                            : null
                                }
                            </li>
                            <li>
                                {selectedLanguage === 'en' ? 'Abdul Haq Matin, Creative Director'
                                    : selectedLanguage === 'tr' ? 'Abdul Haq Matin, Yaratıcı Yönetmen'
                                        : selectedLanguage === 'fa' ? 'عبدالحق متین، مدیر خلاقیت'
                                            : null
                                }
                            </li>
                            <li>
                                {selectedLanguage === 'en' ? 'Freddy Parker, Lead Developer'
                                    : selectedLanguage === 'tr' ? 'Freddy Parker, Baş Geliştirici'
                                        : selectedLanguage === 'fa' ? 'فردی پارکر، توسعه‌دهندهٔ اصلی'
                                            : null
                                }
                            </li>
                            <li>
                                {selectedLanguage === 'en' ? 'Zahidullah Mohammadi, Client Director'
                                    : selectedLanguage === 'tr' ? 'Zahidullah Mohammadi, Müşteri Direktörü'
                                        : selectedLanguage === 'fa' ? 'زاهدالله محمدی، مدیر مشتری'
                                            : null
                                }
                            </li>
                        </ul>
                    </div>

                </div>
            </section>
        </main>
    );
}

export default About;

import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";

// CSS
import './Breadcrumbs.css';

// routes ----------------------------------------------------
const routes = [
    { path: '/services', breadcrumb: { en: 'Services', tr: 'Hizmetler', fa: 'خدمات' } },
    { path: '/projects', breadcrumb: { en: 'Projects', tr: 'Projeler', fa: 'پروژه ها' } },
    { path: '/project_details/:id', breadcrumb: { en: 'Project Details', tr: 'Proje Detayları', fa: 'جزئیات پروژه' } },
    { path: '/about', breadcrumb: { en: 'About Us', tr: 'Hakkımızda', fa: 'درباره ما' } },
    { path: '/contact', breadcrumb: { en: 'Contact Us', tr: 'İletişim', fa: 'تماس با ما' } },
    { path: '/consultation-form', breadcrumb: { en: 'Request Consultation', tr: 'Danışmanlık Talebinde Bulunun', fa: 'درخواست مشاوره' } },
    { path: '/faq', breadcrumb: { en: 'Frequent Answers And Questions', tr: 'SSS', fa: 'سوالات متداول' } },
    { path: '/testimonials', breadcrumb: { en: 'Testimonials', tr: 'Görüşler', fa: 'نظرات مشتریان' } },
];

const Breadcrumbs = ({ onBreadcrumbClick , selectedLanguage}) => {
    const [breadcrumbData, setBreadcrumbData] = useState({});
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    
    useEffect(() => {
        // Simulate fetching dynamic data for breadcrumbs (e.g., API call)
        setTimeout(() => {
            setBreadcrumbData({
                '/users/123': 'John Doe',
                '/products/456': 'Product XYZ'
            });
            setLoading(false);
        }, 1000);
    }, []);

    const matchRoute = (pathname) => {
        for (const route of routes) {
            const routePath = route.path.replace(/:\w+/g, '[^/]+');
            const match = new RegExp(`^${routePath}$`).test(pathname);
            if (match) return route;
        }
        return null;
    };

    useEffect(() => {
        if (location.pathname) {
            const pathnames = location.pathname.split('/').filter(x => x);
            if (pathnames.length > 0) {
                const lastPath = `/${pathnames.join('/')}`;
                const route = matchRoute(lastPath);
                const heading = breadcrumbData[lastPath] || (route && route.breadcrumb && route.breadcrumb[selectedLanguage]);
            } else {
            }
        }
    }, [location, breadcrumbData, selectedLanguage]); // Added `selectedLanguage` here


    const excludedRoutes = ['/login', '/privacy-policy', '/terms-conditions','/cookies-privacy', ''];
    if (loading || excludedRoutes.includes(location.pathname)) return null;


    if (loading) return <div>Loading...</div>;
    if (location.pathname === '/') return null;

    const pathnames = location.pathname.split('/').filter(x => x);
    const breadcrumbItems = pathnames.map((_, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const route = matchRoute(to);
        const breadcrumbLabel = breadcrumbData[to] || (route && route.breadcrumb && route.breadcrumb[selectedLanguage]);
        const isActive = index === pathnames.length - 1;

        return breadcrumbLabel ? (
            <span key={to}>
                <Link
                    to={to}
                    onClick={() => onBreadcrumbClick && onBreadcrumbClick(to)}
                    className={isActive ? 'breadcrumb-active breadcrumbs-link' : 'breadcrumbs-link'}
                    title={breadcrumbLabel}
                >
                    {route && route.icon && <route.icon className="breadcrumb-icon" />}
                    {breadcrumbLabel}
                </Link>
            </span>
        ) : null;
    });

    return (
        <div className="breadcrumbs" lang={selectedLanguage === 'fa' ? 'fa' : 'en'}>
            <div className='breadcrumbs-content' dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                <Link to="/" className="breadcrumbs-home" 
                      onClick={() => onBreadcrumbClick && onBreadcrumbClick('/')}
                >
                    {selectedLanguage === 'en' ? 'Home'
                        : selectedLanguage === 'tr' ? 'Ev'
                            : selectedLanguage === 'fa' ? 'صفحه اصلی'
                                : null
                    }
                </Link>
                
                {selectedLanguage === 'fa' ?
                    <RiArrowLeftSLine className='breadcrumb-icon' />
                    :
                    <RiArrowRightSLine className='breadcrumb-icon' />
                }
                
                {breadcrumbItems.length > 0 ? breadcrumbItems : 'Home'}
            </div>
        </div>
    );
};

export default Breadcrumbs;

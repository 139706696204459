import React, {useState} from 'react'
import './Home.css';
import { FaArrowUp } from "react-icons/fa";
import HomeImage from '../../../assest/Home Image.png';
import {Link} from "react-router-dom";

function Home({scrollToTop, selectedLanguage}) {
    
    return (

        <main className="main" lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <section className="home-section" id="home">
                <div className="home-container container grid">
                    <h1 className="home-title">
                        {selectedLanguage === 'en' ? 'DESIGN THAT ELEVATES YOUR DIGITAL PRESENCE'
                            : selectedLanguage === 'tr' ? 'DİJİTAL VARLIĞINIZI YÜKSELTEN TASARIM'
                                : selectedLanguage === 'fa' ? 'طراحی که حضور دیجیتال شما را ارتقا می‌بخشد'
                                    : null
                        }
                    </h1>
                    <div className="home-img-box">
                        <img src={HomeImage} alt="pic of home" className="home-img"/>
                    </div>
                    <div className="home-info">
                        <Link to='/about' className="home-scroll">
                            <div className="home-scroll-button" onClick={scrollToTop}>
                                <FaArrowUp className='scroll-down-arrow'/>
                            </div>
                            <span className="home-scroll-text">
                                {selectedLanguage === 'en' ? 'About Us'
                                    : selectedLanguage === 'tr' ? 'Hakkımızda'
                                        : selectedLanguage === 'fa' ? 'درباره ما'
                                            : null
                                }
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

        </main>
    );
}

export default Home;




















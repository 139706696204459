import React, { useState } from 'react';
import './Recent Projects.css';
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import { RecentProjectsList } from "../../../Recent Projects List";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';

function RecentProjects({ scrollToTop, selectedLanguage }) {
    const [recentProjectsList] = useState(RecentProjectsList);

    return (
        <main>
            <section
                lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                className="recent-projects-section"
                id="projects"
            >
                <h2 className="section_title-1 recent-project-title">
                    {selectedLanguage === 'en'
                        ? 'Recent Projects'
                        : selectedLanguage === 'tr'
                            ? 'Son Projeler'
                            : selectedLanguage === 'fa'
                                ? 'پروژه های اخیر'
                                : null}
                </h2>

                <div className="recent-projects-swiper container">
                    <Swiper
                        style={{
                            "--swiper-navigation-color": 'var(--black-color-light)',
                            "--swiper-pagination-color": 'var(--black-color-light)'
                        }}
                        centeredSlides={true}
                        grabCursor={true}
                        keyboard={{ enabled: true }}
                        breakpoints={{
                            768: { slidesPerView: 2, slidesPerGroup: 1 },
                            1500: { slidesPerView: 3, slidesPerGroup: 1 },
                        }}
                        navigation={true}
                        pagination={{ clickable: true }}
                        modules={[Keyboard, Navigation, Pagination]}
                        className="recent-projects-swiper"
                    >
                        {recentProjectsList.map((item) => (
                            <SwiperSlide className="slider" key={item.id}>
                                <Link to={`/project_details/${item.id}`}>
                                    <article className="recent-projects-card">
                                        <div className="recent-projects-image">
                                            {item.img.length > 0 && (
                                                <img
                                                    src={item.img[0]}
                                                    alt={item.title[selectedLanguage]}
                                                    className="recent-projects-img"
                                                />
                                            )}
                                            <div
                                                className="recent-projects-button button"
                                                onClick={scrollToTop}
                                            >
                                                <FaArrowRight className="arrow" />
                                            </div>
                                        </div>

                                        <div className="recent-projects-content">
                                            <h3 className="recent-projects-card-title">
                                                {item.title[selectedLanguage]}
                                            </h3>
                                            <p className="recent-projects-card-subtitle">
                                                {item.sub[selectedLanguage]}
                                            </p>
                                        </div>
                                    </article>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </main>
    );
}

export default RecentProjects;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './Testimonials.css';

import { FaQuoteLeft, FaQuoteRight, FaChevronRight, FaChevronLeft } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation, Autoplay } from 'swiper/modules';

function Testimonials({ selectedLanguage }) {
    return (
        <main className="main" lang={selectedLanguage === 'fa' ? 'fa' : 'en'}>
            <section className="testimonials-section">
                <div className="testimonials-container container grid">
                    <h2 className="section_title-1">
                        {selectedLanguage === 'en' ? 'Our Valuable Client'
                            : selectedLanguage === 'tr' ? 'Kıymetli Müşterimiz'
                                : selectedLanguage === 'fa' ? 'مشتری ارزشمند ما'
                                    : null
                        }
                    </h2>
                    <h3 className='testimonials-subtitle'>
                        {selectedLanguage === 'en' ? 'What People Say About Us'
                            : selectedLanguage === 'tr' ? 'İnsanlar Bizim Hakkımızda Ne Diyor'
                                : selectedLanguage === 'fa' ? 'آنچه مردم در مورد ما می گویند'
                                    : null
                        }
                    </h3>

                    <span className='testimonials-left-quote'>
                        <FaQuoteLeft className='testimonials-quote-icon' />
                    </span>
                    <span className='testimonials-right-quote'>
                        <FaQuoteRight className='testimonials-quote-icon' />
                    </span>

                    <Swiper
                        spaceBetween={30}
                        autoplay={{ delay: 10000, disableOnInteraction: false }}
                        loop={true}
                        navigation={{
                            nextEl: '.testimonials-next-button',
                            prevEl: '.testimonials-prev-button'
                        }}
                        modules={[Navigation, Autoplay]}
                        className="testimonials-swiper"
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                        }}
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index} className="testimonial-swiper-slide">
                                <p className="testimonial-text">"{testimonial.quote[selectedLanguage]}"</p>
                                <h3 className="testimonial_author">{testimonial.name[selectedLanguage]}</h3>
                                <p className="testimonial-title recent_projects-subtitle" dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                                    {testimonial.title[selectedLanguage]}
                                </p>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className='testimonials-button-container'>
                        {/* Assign class names for Swiper's navigation */}
                        <button className="testimonials-prev-button button ">
                            <FaChevronLeft className="testimonials-button-icon" />
                        </button>
                        <button className="testimonials-next-button button ">
                            <FaChevronRight className="testimonials-button-icon" />
                        </button>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Testimonials;

const testimonials = [
    {
        name: {
            en: "Emily Watson",
            tr: "Emily Watson",
            fa: "امیلی واتسون"
        },
        title: {
            en: "CEO at Horizon Corp",
            tr: "Horizon Corp CEO'su",
            fa: "مدیر عامل در Horizon Corp"
        },
        quote: {
            en: "Working with this team was a game changer for us. They took our outdated website and transformed it into something modern, fast, and user-friendly. I was truly impressed by their attention to detail and commitment to delivering on time. Our business has seen a significant boost in customer engagement since the launch!",
            tr: "Bu ekiple çalışmak bizim için oyunun kurallarını değiştirdi. Güncellenmemiş web sitemizi aldılar ve modern, hızlı ve kullanıcı dostu bir şeye dönüştürdüler. Detaylara olan dikkatleri ve zamanında teslimat taahhütleri beni gerçekten etkiledi. Lansmandan bu yana işimizde müşteri etkileşiminde önemli bir artış gördük!",
            fa: "کار با این تیم برای ما یک تغییر بزرگ بود. آنها وب‌سایت قدیمی ما را به چیزی مدرن، سریع و کاربرپسند تبدیل کردند. توجه آنها به جزئیات و تعهدشان به تحویل به موقع من را واقعاً تحت تأثیر قرار داد. کسب‌وکار ما از زمان راه‌اندازی شاهد افزایش چشمگیری در تعامل مشتریان بوده است!"
        },
        image: "https://randomuser.me/api/portraits/women/68.jpg",
    },
    {
        name: {
            en: "James Lee",
            tr: "James Lee",
            fa: "جیمز لی"
        },
        title: {
            en: "CTO at Skyway Ventures",
            tr: "Skyway Ventures CTO'su",
            fa: "مدیر فنی در Skyway Ventures"
        },
        quote: {
            en: "As a tech-driven company, we needed a website that reflects our forward-thinking approach. This team understood our needs perfectly and built a website that is not only visually stunning but also highly functional. Their expertise in both design and development really shows in the final product.",
            tr: "Teknoloji odaklı bir şirket olarak ileriye dönük yaklaşımımızı yansıtan bir web sitesine ihtiyacımız vardı. Bu ekip ihtiyaçlarımızı mükemmel bir şekilde anladı ve hem görsel olarak çarpıcı hem de oldukça işlevsel bir web sitesi oluşturdu. Tasarım ve geliştirme konusundaki uzmanlıkları nihai üründe gerçekten kendini gösteriyor.",
            fa: "به عنوان یک شرکت مبتنی بر فناوری، به وب‌سایتی نیاز داشتیم که رویکرد پیشرفته ما را منعکس کند. این تیم نیازهای ما را کاملاً درک کرد و وب‌سایتی ساخت که نه تنها از نظر بصری خیره‌کننده است، بلکه بسیار کارآمد است. تخصص آنها در طراحی و توسعه واقعاً در محصول نهایی نشان داده می‌شود."
        },
        image: "https://randomuser.me/api/portraits/men/32.jpg",
    },
    {
        name: {
            en: "Sophia Patel",
            tr: "Sophia Patel",
            fa: "سوفیا پاتل"
        },
        title: {
            en: "Founder at Bright Solutions",
            tr: "Bright Solutions Kurucusu",
            fa: "موسس Bright Solutions"
        },
        quote: {
            en: "This team provided us with a top-notch website for our e-commerce business. From design to functionality, they executed everything flawlessly. Our online sales have doubled since launching the new site, and we’ve received numerous compliments from customers about the user experience.",
            tr: "Bu ekip, e-ticaret işimiz için bize üst düzey bir web sitesi sağladı. Tasarımdan işlevselliğe kadar her şeyi kusursuz bir şekilde uyguladılar. Yeni siteyi başlattığımızdan beri çevrimiçi satışlarımız ikiye katlandı ve müşterilerden kullanıcı deneyimi hakkında birçok övgü aldık.",
            fa: "این تیم یک وب‌سایت درجه یک برای کسب‌وکار تجارت الکترونیک ما فراهم کرد. از طراحی تا عملکرد، آنها همه چیز را بدون نقص اجرا کردند. از زمان راه‌اندازی سایت جدید، فروش آنلاین ما دو برابر شده و مشتریان بسیاری درباره تجربه کاربری به ما تبریک گفته‌اند."
        },
        image: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
        name: {
            en: "Michael Johnson",
            tr: "Michael Johnson",
            fa: "مایکل جانسون"
        },
        title: {
            en: "Marketing Director at Silverline Media",
            tr: "Silverline Media Pazarlama Direktörü",
            fa: "مدیر بازاریابی در Silverline Media"
        },
        quote: {
            en: "We needed a sleek, responsive, and SEO-friendly website, and this team delivered beyond our expectations. The level of professionalism and creativity was outstanding. They were able to translate our ideas into a visually appealing and highly functional website. Highly recommended!",
            tr: "Şık, duyarlı ve SEO dostu bir web sitesine ihtiyacımız vardı ve bu ekip beklentilerimizi aştı. Profesyonellik ve yaratıcılık seviyesi olağanüstüydü. Fikirlerimizi görsel olarak çekici ve oldukça işlevsel bir web sitesine dönüştürebildiler. Kesinlikle tavsiye edilir!",
            fa: "ما به یک وب‌سایت شیک، پاسخگو و مناسب برای SEO نیاز داشتیم و این تیم فراتر از انتظارات ما عمل کرد. سطح حرفه‌ای بودن و خلاقیت آنها فوق‌العاده بود. آنها توانستند ایده‌های ما را به یک وب‌سایت بصری جذاب و بسیار کارآمد تبدیل کنند. شدیداً توصیه می‌شود!"
        },
        image: "https://randomuser.me/api/portraits/men/45.jpg",
    },
    {
        name: {
            en: "Laura Martinez",
            tr: "Laura Martinez",
            fa: "لورا مارتینز"
        },
        title: {
            en: "Operations Manager at TechyCo",
            tr: "TechyCo Operasyon Müdürü",
            fa: "مدیر عملیات در TechyCo"
        },
        quote: {
            en: "From the initial consultation to the final product, working with this team was a breeze. Their project management skills ensured everything was delivered on time, and the website they built for us is nothing short of impressive. It’s fast, secure, and the design really resonates with our brand.",
            tr: "İlk danışmadan son ürüne kadar bu ekiple çalışmak çok kolaydı. Proje yönetimi becerileri her şeyin zamanında teslim edilmesini sağladı ve bizim için oluşturdukları web sitesi gerçekten etkileyici. Hızlı, güvenli ve tasarım markamızla gerçekten örtüşüyor.",
            fa: "از مشاوره اولیه تا محصول نهایی، کار با این تیم بسیار آسان بود. مهارت‌های مدیریت پروژه آنها تضمین کرد که همه چیز به موقع تحویل داده شود و وب‌سایتی که برای ما ساختند واقعاً چشمگیر است. سریع، امن و طراحی آن واقعاً با برند ما هماهنگ است."
        },
        image: "https://randomuser.me/api/portraits/women/60.jpg",
    }
];

import { IoClose } from "react-icons/io5";
import React, {useEffect, useRef} from "react";
import './Product Viewer.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export function ProductViewer ({closeViewer, selectedProduct} ) {
    const viewRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (viewRef.current && !viewRef.current.contains(event.target)) {
                closeViewer();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!selectedProduct) {
        return null;
    }

    return (
       <div className='product-viewer-section'>
           {selectedProduct ? (
                   <div className="product-viewer-container container grid" ref={viewRef}>
                       <button className='pv-close-button' onClick={closeViewer}>
                           <IoClose className='pv-close-icon'/>
                       </button>

                       <Swiper
                           style={{
                               "--swiper-navigation-color": '#000',
                               "--swiper-pagination-color": '#000'
                           }}
                           lazy={true}
                           pagination={{
                               clickable: true,
                           }}
                           navigation={true}
                           modules={[Pagination, Navigation]}
                           className=" product-viewer-swiper"
                       >
                           {selectedProduct.imageUrl.map((img, imgIndex) => (
                               <SwiperSlide key={imgIndex} className=' product-viewer-slide'>
                                   <img
                                       src={img}
                                       alt={`Img ${imgIndex + 1}`}
                                       className="product-viewer-img "
                                   />
                               </SwiperSlide>
                           ))}

                       </Swiper>
                   </div>
               ) :
               null
           }
       </div>
    )
}

